.App {
  text-align: center;
}

ul {
  list-style:  outside none;
  padding: 0;
}

.accHeader{
  width: -moz-available;
  height: -moz-available;
}

.pointer:hover{
  cursor: pointer;
}